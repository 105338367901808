import { CONTENT_SUBJECT_REQUIRED, SUBJECT_REQUIRED } from "Constants";
import _ from "lodash";

export const transformMaxScoreValue = (objMaxScore) => {
  let obj = {};
  for (const [key, value] of Object.entries(objMaxScore)) {
    let arrNameCourse = [];
    let arrValueCourse = {};
    if (!_.isEmpty(value)) {
      arrValueCourse = { key };
      for (const [it, vl] of Object.entries(value)) {
        arrNameCourse.push({
          title: it,
          dataIndex: it,
          key: it,
          align: "center",
        });
        arrValueCourse = { ...arrValueCourse, [`${it}`]: vl };
        obj = {
          ...obj,
          [`${key}`]: {
            nameCourse: arrNameCourse,
            valueCourse: [arrValueCourse],
          },
        };
      }
    }
  }
  return obj;
};

export const transformContentSubject = (selectedContent) => {
  let arr = [];
  if(!_.isEmpty(selectedContent)){
    for (const [key, value] of Object.entries(selectedContent)) {
      value.length &&
      arr.push({
        name: key,
        itemChecked: value
      }) 
    }
  }
  return arr
};

export const convertValueChecked = (subjects, arrCompulsory) => {
  let obj = {};
  let contentRequired = arrCompulsory
  const sameSubject = _.intersection(CONTENT_SUBJECT_REQUIRED, arrCompulsory)
  contentRequired = _.difference(arrCompulsory, CONTENT_SUBJECT_REQUIRED)
  for (let i = 0; i < subjects.length; i++) {
    const arr = _.intersection(contentRequired, subjects[i].child)
    if(arr.length === 0 && contentRequired.includes(subjects[i].name)){
      // const valueInCompulsorySubjects = _.intersection(subjects[i].child, contentRequired)
      obj = {...obj, [`${subjects[i].name}`] : subjects[i].child}
    }else{
      const valueInCompulsorySubjects = _.intersection(subjects[i].child, contentRequired)
      obj = {...obj, [`${subjects[i].name}`] : valueInCompulsorySubjects}
    }
  }
  if(sameSubject.length ){
    obj = {...obj,[`${SUBJECT_REQUIRED}`] : [...obj[SUBJECT_REQUIRED], ...sameSubject]}
  }
  return obj
};
