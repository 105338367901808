export const STORAGE = {
	LANGUAGE: 'LANGUAGE',
	USER_TOKEN: 'USER_TOKEN',
	USER_DATA: 'USER_DATA',
	ADMIN_TOKEN: 'ADMIN_TOKEN',
	ROLE_ADMIN: 'ROLE_ADMIN',
};

export function getLocalStorage(key) {
	return localStorage.getItem(key);
}

export function setLocalStorage(key, value) {
	return localStorage.setItem(key, value);
}

export function removeLocalStorage(key) {
	return localStorage.removeItem(key);
}

export function clearLocalStorage() {
	return localStorage.clear();
}