import { push } from 'connected-react-router'
import { put, select } from 'redux-saga/effects'

import Notification from 'Components/Notification'
import { TYPES } from 'Store/Actions'
import Misc from './misc'
import { STORAGE, clearLocalStorage, removeLocalStorage } from './storage'
import { ERROR_API_MESSAGE } from 'Constants'
// import AppLocale from '../lngProvider'

export default function sagaHelper({ api }) {
  return function* ({ type, data, callback }) {
    const successType = `${type}_SUCCESS`
    const failureType = `${type}_FAILURE`

    try {
      yield put({ type: `${type}_REQUEST`, payload: data })

      const res = yield api(data)
      const { status } = res

      if (status === 200) {
        yield put({ type: successType, data: res.data, payload: data })
        if (callback) callback(successType, res.data)
      } else {
        throw res
      }
    } catch (e) {
      
      const error = yield Misc.getErrorJsonBody(e)
      if (error) {
        // const locale = yield select((state) => state.settings.locale)
        const { location: { pathname } } = yield select((state) => state.router)
        if (error.error === 13) {
          if (pathname !== '/signin') {
            Notification.error(ERROR_API_MESSAGE[e.message_code] || e.message);
          }
          clearLocalStorage()
          yield put({ type: TYPES.TOKEN_EXPIRED })
          yield put(push('/signin'))
        } else if (error.error === 12) {
          if (pathname !== '/signin') {
            Notification.error(ERROR_API_MESSAGE[e.message_code] || e.message);
          }
          clearLocalStorage()
          yield put(push('/signin'))
          yield put({ type: TYPES.INVALID_TOKEN })
        }
        else if (error.message_code === 30) {
          yield put(push(`/maintenance`))
        }
        else if (
          error.message_code === 100
          || error.message_code === 101
          || error.message_code === 102
          || (type === TYPES.GET_STUDENT_INFORMATION && (error.message_code === 3 || error.message_code === 4 || error.message_code === 5)))
        {
          if (pathname.includes('/admin')) {
            removeLocalStorage(STORAGE.ADMIN_TOKEN)         
            yield put(push('/admin/login'))
          } else {
            yield put(push('/error', {
              code_error: error.statusCode || 404
            }))
          }
        } else if (error.statusCode === 502) {
          Notification.error(ERROR_API_MESSAGE['ERR_NETWORK'] || e.message);
        }
        else {
          Notification.error(ERROR_API_MESSAGE[e.message_code || e.code] || e.message);
        }
      } else {
        Notification.error('Fail to fetch')
      }

      yield put({ type: failureType, data: error || e })
      if (callback) callback(failureType, error || e)
    }
  }
}
