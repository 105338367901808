export function convertQueryString(obj) {
  let str = [];
  for (let p in obj)
    if (obj.hasOwnProperty(p) && p !== `studentID` && p !== `studentName`) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}
export function convertQueryStringAdmin(obj) {
  let str = [];
  for (let p in obj) {
    if (obj.hasOwnProperty(p) && p !== `studentID` && p !== `studentName`) {
      const encodedValue = encodeURIComponent(obj[p]);
      const decodedValue = decodeURIComponent(encodedValue);
      str.push(encodeURIComponent(p) + "=" + decodedValue);
    }
  }
  return str.join("&");
}
