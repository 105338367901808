import lodash from 'lodash'

function isFetchError(error) {
  return !!error && lodash.hasIn(error, 'status') && lodash.isFunction(error.json)
}

async function getFetchError(error) {
  try {
    return await error.json()
  } catch (e) {
    return null
  }
}

let isMobile = false
let isFirefox = false
let isSafari = false

// check mobile
if (
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
) {
  isMobile = true
}
// check android

// check firefox
if (/Firefox/i.test(navigator.userAgent)) {
  isFirefox = true
}

// check safari
if (/^((?!Chrome|Android).)*Safari/i.test(navigator.userAgent)) {
  isSafari = true
}

export default class Misc {
  constructor() {
    this.trimObjectProperties = this.trimObjectProperties.bind(this)
    this.getImageURL = this.getImageURL.bind(this)
    this.getErrorJsonBody = this.getErrorJsonBody.bind(this)
  }


  static isMobile = isMobile;

  static isFirefox = isFirefox;

  static isSafari = window.safari !== undefined || isSafari;

  static isWalletConnect = isFirefox || this.isSafari;

  static isAndroid = /(android)/i.test(navigator.userAgent); 
  
  static trimObjectProperties(obj, properties) {
    const data = lodash.cloneDeep(obj)

    if (lodash.isArray(properties)) {
      properties.forEach((property) => {
        data[property] = data[property]?.trim()
      })
    } else {
      lodash.keys(obj).forEach((key) => {
        data[key] = data[key]?.trim()
      })
    }

    return data
  }

  static getImageURL(name) {
    return name && `${process.env.REACT_APP_API_ENDPOINT}/${name}`
  }

  static trimPublicAddress = (string, numberOfCharacter) => string && (
      `${string.slice(0, numberOfCharacter)} . . . ${string.slice(string.length - numberOfCharacter, string.length)}`
  )

  static trimName = (string, numberOfCharacter) => string && (
      `${string.slice(0, numberOfCharacter)} . . . `
  )

  static async getErrorJsonBody(error) {
    if (isFetchError(error)) {
      error = await getFetchError(error)
    }

    return error
  }
}
