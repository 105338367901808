/*eslint-disable */
export const regex = {
  integerNumber0: /^[1-9]\d*$/,
  outString: /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/,
  decimalNumber: /^\d*(?:\.\d{0,6})?$/,
  decimalNumber6: /^\$?\d+(\.\d{0,6})?$/,
  decimal0to99_2digit: /^(?=.*\d)\d{0,2}(?:\.\d{0,2})?$/,
  interger0to8digit: /^[0-9]{0,8}$/,
  numberAndCharacters8digit: /^[A-Za-z0-9]{0,8}$/,
  negationLettersAndNumber: /[^A-Za-z0-9\-._ \u3040-\u309F\u30A0-\u30FF\u3400-\u4DBF\u4E00-\u9FFF\uF900-\uFAFF ]/g,
  negationNumber: /[^\d,]/g,
  LettersNumberAndFewSpecialCharacters: /^[A-Za-z0-9,\-._ ぁ-んァ-ン一-龠]*$/,
  numberAndComma : /^[\d,]$/,
}